export const API_ROOT_URL: string = "https://tur-admin.plka.pl";
export const REST_API_ROOT_ENDPOINT_URL: string = `${API_ROOT_URL}/api/v1`;


export const API_LEAGUES_LIST_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/leagues/leagues/";
export const API_LEAGUE_ENDPOINT_URL = API_LEAGUES_LIST_ENDPOINT_URL + "{LEAGUE_ID}/";
export const API_LEAGUE_ROUNDS_LIST_ENDPOINT_URL = API_LEAGUE_ENDPOINT_URL + "league_rounds/";
export const API_LEAGUE_STATISTICS_ENDPOINT_URL = API_LEAGUE_ENDPOINT_URL + "get_league_statistics/";

export const API_TEAMS_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/teams/teams/";
export const API_TEAM_ENDPOINT_URL = API_TEAMS_ENDPOINT_URL + "{TEAM_ID}" + "/";
export const API_TEAM_BY_ID_SLUG_ENDPOINT_URL = API_TEAMS_ENDPOINT_URL + "team-id-by-slug/";
export const API_TEAM_FULL_INFORMATION_ENDPOINT_URL = API_TEAMS_ENDPOINT_URL + "{TEAM_ID}" + "/team-information/";
export const API_TEAM_ACTIVE_SEASON_LEAGUE_ENDPOINT_URL = API_TEAM_ENDPOINT_URL + "team-active-season-league/";
export const API_TEAM_NEXT_GAMES_IN_ACTIVE_SEASON_ENDPOINT_URL = API_TEAM_ENDPOINT_URL + "team-next-games-in-active-season/";
export const API_TEAM_PLAYERS_ENDPOINT_URL = API_TEAM_ENDPOINT_URL + "get-team-players/";
export const API_TEAM_PLAYER_ADD_ENDPOINT_URL = API_TEAM_ENDPOINT_URL + "add-team-player/";
export const API_TEAM_LEAGUES_SQUADS_ENDPOINT_URL = API_TEAM_ENDPOINT_URL + "team-leagues-squads/";
export const API_ADD_PLAYER_TO_TEAM_LEAGUE_SQUAD_ENDPOINT_URL = API_TEAM_ENDPOINT_URL + "add-player-to-team-league-squad/";

export const API_PLAYERS_LIST_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/teams/players/";
export const API_PLAYER_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/teams/players/{PLAYER_ID}/";
export const API_PLAYER_SEND_EMAIL_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/teams/players/{PLAYER_ID}/send-email-to-player/";
export const API_PLAYER_FREE_AGENTS_REQUEST_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/teams/players/{PLAYER_ID}/get-player-free-agents-request/";

export const API_REGISTER_NEW_PLAYER_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/teams/players/register-new-player/";

export const API_TEAM_PLAYERS_LIST_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "teams/players/team-players/";
export const API_TEAM_PLAYER_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "teams/players/team-players/{PLAYER_ID}/";


export const API_FREE_AGENTS_LIST_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/teams/players/free-agents/";
export const API_FREE_AGENTS_LIST_FOR_ACTIVE_SEASON_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/teams/players/free-agents/get-free-agent-requests-for-active-season/";


export const API_ARTICLES_LIST_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/articles/list/";
export const API_ARTICLE_BY_SLUG_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/articles/list/article-by-slug/";

export const API_FILES_FOR_DOWNLOAD_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/content/files/groups/";
export const API_HOME_PAGE_SLIDES_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/content/home-page-slides/";

export const API_PARTNERS_LIST_ENDPOINT_URL = REST_API_ROOT_ENDPOINT_URL + "/settings/sponsors/";
